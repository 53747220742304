<template>
  <!-- <div class="emb-FinalReceipt-wrap" v-if="userDetails"> -->
  <div class="emb-FinalReceipt-wrap">
    <div class="d-flex justify-center text-h4 ma-6 pt-3 font-weight-bold">
      {{ $t("message.paymentPage.title") }}
    </div>
    <div class="final-receipt section-gap" v-if="invoice != null">
      <v-container grid-list-xl py-0>
        <v-layout align-center justify-center>
          <v-flex xs12 sm12 md10 lg7 xl7>
            <div class="emb-card pb-12">
              <div id="payment-receipt">
                <v-banner class="mb-3 font-weight-black yellow accent-2">
                  <v-container>
                    <v-row>
                      <v-col cols="1">
                        <div class="text-center">
                          <v-icon class="red--text">mdi-information</v-icon>
                        </div>
                      </v-col>
                      <v-col cols="9" sm="9" md="7" lg="4" xl="4">
                        <div
                          class="
                            text-center text-h4 text-lg-h3 text-xl-h3
                            red--text font-weight-bold
                          "
                        >
                          {{
                            $t("message.finalReceiptPage.interacWarningTitle")
                          }}
                        </div>
                        <!-- {{ $t("message.orderViewPage.unavailable") }} -->
                      </v-col>
                      <v-col cols="1">
                        <div class="text-center">
                          <v-icon class="red--text">mdi-information</v-icon>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="11" md="9" lg="7" xl="7">
                        <div class="text-center red--text text-h5">
                          {{ $t("message.finalReceiptPage.interacWarningMsg") }}
                        </div>
                        <div
                          class="text-center black--text text-h4 font-weight-bold"
                        >
                          {{ $t("message.interacEmail") }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-banner>
                <div class="text-center bg-grey pa-sm-12 pa-6">
                  <h3>{{ $t("message.finalReceiptPage.thankyou") }}</h3>
                  <h6 class="mb-6">
                    {{
                      $t("message.finalReceiptPage.thanksMessage", {
                        email: getUser.email,
                      })
                    }}
                  </h6>
                  <h6>
                    <p
                      v-html="
                        $t('message.finalReceiptPage.paymentInfo', {
                          amount: $formatMoney(
                            invoice.total_amount_currency.amount,
                            2,
                            invoice.total_amount_currency.currency,
                            1
                          ),
                        })
                      "
                    ></p>
                    <div class="yellow accent-2 ma-4">
                      <p
                        class="text-center"
                        v-html="$t('message.paymentPage.notice')"
                      ></p>
                    </div>
                  </h6>
                  <h6 class="red--text mb-6">
                    {{ $t("message.finalReceiptPage.verifyJunkmail") }}:
                  </h6>

                  <h6 class="mb-6">
                    {{ $t("message.finalReceiptPage.receiptNumber") }}:
                    {{ invoice.receipt_id }}
                  </h6>
                  <img
                    src="/static/images/checked.png"
                    width="64"
                    height="64"
                    alt="Success"
                  />
                </div>
                <div class="py-sm-12 py-8 px-sm-6 px-4">
                  <v-layout row wrap mb-6>
                    <v-flex xs12 sm6 md6 lg6 xl6>
                      <h6>{{ $t("message.finalReceiptPage.summary") }}</h6>
                      <p class="mb-1">
                        {{ $t("message.finalReceiptPage.receiptNumber") }}:
                        {{ invoice.receipt_id }}
                      </p>
                      <p class="mb-1">
                        {{ $t("message.finalReceiptPage.orderId") }}:
                        {{ invoice.id }}
                      </p>
                      <p class="mb-1">
                        {{ $t("message.finalReceiptPage.orderDate") }}:
                        {{ invoice.order_date.toDate().toLocaleDateString() }} -
                        {{ invoice.order_date.toDate().toLocaleTimeString() }}
                      </p>
                      <p class="mb-1">
                        {{ $t("message.finalReceiptPage.orderTotal") }}:
                        <emb-currency-sign></emb-currency-sign
                        >{{
                          $formatMoney(
                            invoice.total_amount_currency.amount,
                            2,
                            invoice.total_amount_currency.currency,
                            1
                          )
                        }}
                      </p>
                    </v-flex>
                    <!-- <v-flex xs12 sm6 md6 lg6 xl6>
											<h6>Ship To</h6>
											<p class="mb-1">{{userDetails.firstName}} {{userDetails.lastName}}</p>
											<p class="mb-1">{{userDetails.aptBuilding}}</p>
                                 <p class="mb-1">{{userDetails.cityState}}</p>
                                 <p class="mb-1">{{userDetails.country}} - {{userDetails.zipCode}}</p>
                                 <p class="mb-1">Contact No. {{userDetails.phone}}</p>
                              </v-flex> -->
                  </v-layout>
                  <!-- <div class="py-6 text-center bg-grey px-3">
                    <h4>Expected Date of Delivery</h4>
                    <h3>{{ invoiceData.deliveryDate }}</h3>
                  </div> -->
                  <h4 class="pt-6">
                    {{ $t("message.finalReceiptPage.orderDetails") }}
                  </h4>
                  <!-- <template v-for="(cart, index) in invoiceData.products">
                    <div
                      class="
                        layout
                        row
                        wrap
                        align-center
                        d-flex
                        my-0
                        text-md-left text-center
                      "
                      v-if="invoiceData.products"
                      :key="index"
                    >
                      <v-flex xs12 sm12 md3 lg3 xl3>
                        <img alt="cart Image" width="100" :src="cart.image" />
                      </v-flex>
                      <v-flex xs12 sm4 md3 lg3 xl3>
                        <h6>Product Name</h6>
                        <p class="mb-0 font-weight-bold">{{ cart.name }}</p>
                      </v-flex>
                      <v-flex xs6 sm4 md3 lg3 xl3>
                        <h6>Quantity</h6>
                        <p class="mb-0">{{ cart.quantity }}</p>
                      </v-flex>
                      <v-flex xs6 sm4 md3 lg3 xl3>
                        <h6>Price</h6>
                        <p class="mb-0">${{ cart.price }}</p>
                      </v-flex>
                    </div>
                  </template> -->
                  <v-divider class="my-4"></v-divider>
                  <div>
                    <div
                      class="
                        d-flex
                        align-center
                        justify-space-between
                        mt-4
                        mb-4
                      "
                    >
                      <div>
                        <p class="mb-0">{{ $t("message.billing.subtotal") }}</p>
                      </div>
                      <div>
                        <span
                          ><emb-currency-sign></emb-currency-sign
                          >{{
                            $formatMoney(
                              invoice.total_amount_currency.sub_total_amount,
                              2,
                              invoice.total_amount_currency.currency,
                              1
                            )
                          }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="d-flex align-center justify-space-between mb-4"
                      v-if="invoice.coupon_amount != undefined"
                    >
                      <p class="mb-0">{{ $t("message.billing.coupon") }}</p>
                      <span
                        >-<emb-currency-sign></emb-currency-sign
                        >{{
                          $formatMoney(
                            invoice.total_amount_currency.coupon_amount,
                            2,
                            invoice.total_amount_currency.currency,
                            1
                          )
                        }}</span
                      >
                    </div>
                    <div
                      class="d-flex align-center justify-space-between mb-4"
                      v-if="invoice.credit != 0"
                    >
                      <p class="mb-0">{{ $t("message.billing.credit") }}</p>
                      <span
                        >-<emb-currency-sign></emb-currency-sign
                        >{{
                          $formatMoney(
                            invoice.total_amount_currency.credit,
                            2,
                            invoice.total_amount_currency.currency,
                            1
                          )
                        }}</span
                      >
                    </div>
                  </div>
                  <v-divider class="my-4"></v-divider>
                  <div class="d-flex align-center justify-space-between">
                    <h4>{{ $t("message.billing.total") }}</h4>
                    <h4>
                      <emb-currency-sign></emb-currency-sign
                      >{{
                        $formatMoney(
                          invoice.total_amount_currency.amount,
                          2,
                          invoice.total_amount_currency.currency,
                          1
                        )
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="layout row wrap pt-6 px-4 text-center">
                <v-flex text-md-right>
                  <h6 class="mt-6">
                    {{ $t("message.finalReceiptPage.orderStatusDesc") }}
                  </h6>
                  <v-btn to="/myTickets?tab=orders" class="primary">
                    {{ $t("message.finalReceiptPage.goToOrders") }}
                  </v-btn>
                </v-flex>
              </div>
              <div class="layout row wrap pt-6 px-4 text-center">
                <v-flex text-md-right>
                  <h6>Ou</h6>
                  <v-btn class="accent" large to="/">{{
                    $t("message.finalReceiptPage.goToHome")
                  }}</v-btn>
                </v-flex>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  computed: {
    ...mapGetters([
      // "tax",
      "shipping",
      "userDetails",
      "lotteries_info",
      "getUser",
    ]),
  },
  data() {
    return {
      invoice: null,
    };
  },
  mounted() {
    var order_id = this.$route.params.order_id;
    if (order_id != null) {
      const orderDocRef = doc(getFirestore(), "orders", order_id);
      getDoc(orderDocRef)
        .then((documentSnapshot) => {
          if (documentSnapshot.data() == undefined) {
            this.$router.push("/home");
            return;
          }
          this.invoice = documentSnapshot.data();
        })
        .catch(() => {
          this.$router.push("/home");
        });
    } else {
      this.$router.push("/home");
    }
  },
  methods: {
    /**
     * this function returns the delivery date
     **/
    deliveryDate() {
      let format = "MMMM Do , YYYY";
      let startdate = moment().format(format);
      var new_date = moment(startdate, format).add(5, "days");
      return new_date.format(format);
    },
    /**
     * this function returns the total for the item
     **/
    itemTotal() {
      return 0;
      // let productTotal = 0;
      // if (this.invoiceData.products && this.invoiceData.products.length > 0) {
      //   for (const item of this.invoiceData.products) {
      //     productTotal += item.price * item.quantity;
      //   }
      //   return productTotal.toFixed(2);
      // } else {
      //   return productTotal;
      // }
    },
    /**
     * this function returns the total price
     **/
    getTotalPrice() {
      return 0;
      // let totalPrice = this.tax + this.shipping;
      // if (this.invoiceData.products && this.invoiceData.products.length > 0) {
      //   for (const item of this.invoiceData.products) {
      //     totalPrice += item.price * item.quantity;
      //   }
      //   return totalPrice.toFixed(2);
      // } else {
      //   return totalPrice.toFixed(2);
      // }
    },
  },
};
</script>
